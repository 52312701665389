import { isRef, type MaybeRef, type Ref } from 'vue';
import type { GenericObject } from 'vee-validate';
import { useForm } from 'vee-validate';
import type { TranslationKey } from '@/i18n';
import { FrontendError } from '@/utils/error-helpers';
import Logger from 'js-logger';
import type { ObjectSchema } from 'yup';

const logger = Logger.get('runWithLoadingAndError');

type ErrorHandler = Ref<TranslationKey | undefined> | ((error: TranslationKey) => void);

export function runWithLoadingAndError<A extends unknown[] = unknown[], T = unknown>(
    run: (...args: A) => Promise<T>,
    loading: Ref<boolean>,
    errorHandler: ErrorHandler,
): (...args: A) => Promise<T | undefined> {
    return async (...args: A) => {
        if (isRef(errorHandler)) {
            errorHandler.value = undefined;
        }
        loading.value = true;
        let result;
        try {
            result = await run(...args);
        } catch (error) {
            logger.error(error);
            const errorTranslation = error instanceof FrontendError ? error.key : 'errors.frontend.general';
            if (isRef(errorHandler)) {
                errorHandler.value = errorTranslation;
            } else {
                errorHandler(errorTranslation);
            }
        }
        loading.value = false;
        return result;
    };
}

export function runWithForm<T extends GenericObject, R = unknown>(
    validationSchema: MaybeRef<ObjectSchema<T>>,
    run: (values: T) => Promise<R>,
    loading: Ref<boolean>,
    error: ErrorHandler,
) {
    const { values, resetForm, meta, isFieldDirty, handleSubmit, setFieldError } = useForm<T>({ validationSchema });

    return {
        values,
        resetForm,
        meta,
        isFieldDirty,
        setFieldError,
        run: handleSubmit(runWithLoadingAndError(run, loading, error)),
    };
}
