<template>
    <div class="d-flex flex-column align-items-center justify-content-center">
        <img src="@/assets/img/loading-indicator.gif" alt="Loading indicator" class="loading-indicator mb-4" />
        <h2 class="loading-text">{{ loadingMessage }}</h2>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
    loadingMessages?: string[];
}>();

const { tm } = useI18n();

const loadingMessage = computed(() => {
    const messages = props.loadingMessages ? props.loadingMessages : tm('loadingMessages');
    return messages[Math.floor(Math.random() * messages.length)];
});
</script>

<style scoped lang="scss">
.loading-indicator {
    height: 100px;
}

.loading-text {
    color: #556579;
}
</style>
