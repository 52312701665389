import type { EKeyResolvedRecursive, ElementConfig, ElementType } from '@/editor/core/types/editor-types';

function processElement(
    elementTemplate: any,
    element: any,
    actions: Array<{ conditionFn: (value: any) => boolean; actionFn: (template: any, element: any, key: any) => void }>,
): void {
    if (!elementTemplate) {
        return;
    }
    if (Array.isArray(elementTemplate)) {
        console.assert(
            Array.isArray(element),
            'elementTemplate is array but element is not array',
            elementTemplate,
            element,
        );
        console.assert(elementTemplate.length === element.length, 'elementTemplate and element have different lengths');
        for (let i = 0; i < elementTemplate.length; i++) {
            const template = elementTemplate[i];
            let matchedAction = false;
            for (const action of actions) {
                if (action.conditionFn(template)) {
                    matchedAction = true;
                    action.actionFn(template, element, i);
                    continue;
                }
            }
            if (!matchedAction) {
                processElement(template, element[i], actions);
            }
        }
    } else if (typeof elementTemplate === 'object') {
        console.assert(
            typeof element === 'object',
            'elementTemplate is object but element is not object',
            elementTemplate,
            element,
        );
        for (const key in elementTemplate) {
            const template = elementTemplate[key];
            let matchedAction = false;
            for (const action of actions) {
                if (action.conditionFn(template)) {
                    matchedAction = true;
                    action.actionFn(template, element, key);
                    continue;
                }
            }
            if (!matchedAction) {
                processElement(template, element[key], actions);
            }
        }
    }
}

interface ElementStructureElement<T extends ElementType = ElementType> {
    elementTemplate: any;
    resolvedElement: EKeyResolvedRecursive<ElementConfig<T>>;
    translations: Record<string, Record<string, string>>;
    type: T;
}

export function useElementStructure() {
    let language = 'en';
    const elements = new Map<string, ElementStructureElement>();

    function addElements(
        elementDefinitions: Array<{
            id: string;
            // TODO: this should be config and not element
            element: any;
            type: ElementType;
            translations?: Record<string, Record<string, string>>;
        }>,
    ) {
        for (const { id, type, element, translations } of elementDefinitions) {
            const elementData = {
                type,
                elementTemplate: element,
                resolvedElement: JSON.parse(JSON.stringify(element)),
                translations: translations ?? {},
            };
            elements.set(id, elementData);
        }

        linkElements();
        applyTranslations();
    }

    function getElement(id: string) {
        return elements.get(id);
    }

    function isEKey(value: any): value is { _eKey: string } {
        return value && typeof value === 'object' && '_eKey' in value;
    }
    function isTKey(value: any): value is { _tKey: string } {
        return value && typeof value === 'object' && '_tKey' in value;
    }

    function linkElements(): void {
        function linkAction(template: any, element: any, key: any): void {
            if (isTKey(template)) {
                return;
            }
            const elementData = elements.get(template._eKey);
            element[key] = elementData?.resolvedElement;
        }

        elements.forEach(({ elementTemplate, resolvedElement }) =>
            processElement(elementTemplate, resolvedElement, [
                { conditionFn: isEKey, actionFn: linkAction },
                { conditionFn: isTKey, actionFn: () => {} },
            ]),
        );
    }

    function applyTranslations(): void {
        elements.forEach(({ elementTemplate, resolvedElement, translations }) => {
            function applyAction(template: any, element: any, key: any): void {
                element[key] = { _tKeyResolved: translations[language]?.[template._tKey] };
            }
            processElement(elementTemplate, resolvedElement, [
                { conditionFn: isTKey, actionFn: applyAction },
                { conditionFn: isEKey, actionFn: () => {} },
            ]);
        });
    }

    function setLanguage(newLanguage: string) {
        language = newLanguage;
        applyTranslations();
    }

    function getElementsByType<T extends ElementType>(type: T | T[]) {
        const filter = Array.isArray(type) ? type : [type];
        return Array.from(elements.values()).filter((element) =>
            filter.includes(element.type as T),
        ) as unknown as Array<ElementStructureElement<T>>;
        // TODO: Should work with this:
        // return Array.from(elements.values()).filter((element) => filter.includes(element.type as T)) as Array<
        //     ElementStructureElement<T>
        // >;
    }

    function getElementById<T extends ElementType>(id: string, expectedType: T) {
        const element = elements.get(id);
        if (element?.type !== expectedType) {
            throw new Error(`Element with id ${id} is not of type ${expectedType}`);
        }
        element;
        return element as unknown as ElementStructureElement<T>;
        // TODO: Should work with this:
        //return element as ElementStructureElement<T>;
    }

    return {
        addElements,
        getElement,
        getElementById,
        setLanguage,
        linkElements,
        getElementsByType,
    };
}
