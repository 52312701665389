import { computed, type Ref, watch, ref, reactive } from 'vue';
import type { Simulation } from '@/types/simulation';
import { isNonNullable, isNullable } from '@/utils/nullability-helpers';
import {
    getSimulationResults,
    getSimulationDecisions,
    listenToSimulation,
    listenToSimulationResults,
    listenToSimulationDecisions,
    getTeamsWithUsers,
    getSimulation,
} from '@/services/simulation-service';
import { DateTime } from 'luxon';
import { useAsyncState } from '@vueuse/core';
import { createInjectionState } from '@vueuse/core';
import { FrontendError } from '@/utils/error-helpers';
import { supabaseClient } from '@/services/supabase';
import { useSimulationStorage } from './useSimulationStorage';
import type { FrontendScenarioDefinition } from '@shared-types/frontend-scenario-definition';
import { EdgeFunctionsManager } from '@/utils/edge-functions-manager';
import { useElementStructure } from '@/editor/core/use-element-structure';
import { currentLanguage } from '@/i18n';
import { runWithLoadingAndError } from './runWithLoadingAndError';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';

// Only use this in areas of the simulation view that are shown if the simulation is loaded.
export const [provideSimulationInstanceStore, useSimulationInstanceStore] = createInjectionState(
    (simulationID: Ref<string>) => {
        const { t } = useI18n();
        const isLoadingLanguage = ref(false);

        const { setLanguage } = useElementStructure();
        setLanguage(currentLanguage.value);

        const {
            state: _simulation,
            error,
            isLoading: isFetchingSimulation,
            execute: loadSimulation,
        } = useAsyncState<Simulation | undefined>(
            async () => {
                const scenarioPromise = EdgeFunctionsManager.getScenarioDefinition({
                    simulationID: simulationID.value,
                    language: currentLanguage.value,
                });

                const [simulationBasics, teams, results, decisions, definition] = await Promise.all([
                    getSimulation(simulationID.value),
                    getTeamsWithUsers(simulationID.value, scenarioPromise),
                    getSimulationResults(simulationID.value),
                    getSimulationDecisions(simulationID.value),
                    scenarioPromise,
                ]);
                if (isNullable(simulationBasics) || isNullable(decisions)) {
                    throw new FrontendError('errors.frontend.simulationFetchFailed');
                }

                const {
                    id,
                    name,
                    periodKey,
                    isPeriodUnlocked,
                    startDate,
                    endDate,
                    periodEndTime,
                    modifiers,
                    isInstructorManaged,
                } = simulationBasics;

                return {
                    id,
                    name,
                    isInstructorManaged,
                    modifiers: modifiers ?? undefined,
                    periodKey,
                    isPeriodUnlocked,
                    periodEndTime: isNullable(periodEndTime) ? undefined : DateTime.fromISO(periodEndTime),
                    teams,
                    results,
                    decisions,
                    scenario: definition,
                    startDate: DateTime.fromISO(startDate),
                    endDate: DateTime.fromISO(endDate),
                };
            },
            undefined,
            { immediate: false, resetOnExecute: false },
        );

        const simulation = reactive(_simulation);

        watch(currentLanguage, async (newLanguage) => {
            setLanguage(newLanguage);

            // Load scenario
            const loadLanguage = runWithLoadingAndError(
                async () => {
                    if (isNullable(simulation.value)) {
                        return;
                    }

                    const scenario = await EdgeFunctionsManager.getScenarioDefinition({
                        scenarioID: simulation.value?.scenario.id,
                        language: newLanguage,
                    });

                    simulation.value.scenario = scenario;
                },
                isLoadingLanguage,
                () => {
                    notify({
                        type: 'error',
                        text: t('errors.frontend.scenarioTranslationError'),
                        ignoreDuplicates: true,
                    });
                },
            );

            loadLanguage();
        });

        const simulationLoadingError = computed(() => (error.value as Error)?.message);
        const hasSimulationInstanceLoadedSuccessfully = computed(
            () => !isFetchingSimulation.value && isNullable(simulationLoadingError.value),
        );

        watch(
            simulationID,
            (_, __, onCleanup) => {
                loadSimulation();

                const simulationListener = listenToSimulation(simulationID.value, (simulationBasics) => {
                    if (isNullable(simulation.value) || isNullable(simulationBasics)) {
                        throw new Error('Simulation not found! Simulation basics were deleted.');
                    }

                    const { id, name, periodKey, isPeriodUnlocked, endDate, periodEndTime, modifiers } =
                        simulationBasics;

                    simulation.value = {
                        ...simulation.value,
                        id,
                        name,
                        modifiers: modifiers ?? undefined,
                        periodKey,
                        isPeriodUnlocked,
                        endDate: DateTime.fromISO(endDate),
                        periodEndTime: isNullable(periodEndTime) ? undefined : DateTime.fromISO(periodEndTime),
                    };
                });
                const simulationResultsListener = listenToSimulationResults(simulationID.value, (simulationResults) => {
                    if (isNullable(simulation.value) || isNullable(simulationResults)) {
                        throw new Error('Simulation not found! Simulation results were deleted.');
                    }
                    const { data, periodKey, teamKey } = simulationResults;
                    const currentData = simulation.value.results;
                    currentData[periodKey] = {
                        ...currentData[periodKey],
                        [teamKey]: data,
                    };
                    // We have to make sure to change the reference of the object to trigger reactivity
                    simulation.value = {
                        ...simulation.value,
                        results: currentData,
                    };
                });
                const simulationDecisionsListener = listenToSimulationDecisions(
                    simulationID.value,
                    (simulationDecisions) => {
                        if (isNullable(simulation.value) || isNullable(simulationDecisions)) {
                            throw new Error('Simulation not found! Simulation decisions were deleted.');
                        }
                        const { decisions, initialDecisions, periodKey, hasBeenSubmitted, teamKey } =
                            simulationDecisions;
                        const currentDecisions = simulation.value.decisions;
                        simulation.value.decisions[periodKey] = {
                            ...currentDecisions[periodKey],
                            [teamKey]: {
                                decisions,
                                initialDecisions,
                                hasBeenSubmitted,
                            },
                        };
                        // We have to make sure to change the reference of the object to trigger reactivity
                        simulation.value = {
                            ...simulation.value,
                            decisions: currentDecisions,
                        };
                    },
                );

                onCleanup(() => {
                    simulationListener.unsubscribe();
                    simulationResultsListener.unsubscribe();
                    simulationDecisionsListener.unsubscribe();
                });
            },
            { immediate: true },
        );

        useSimulationStorage(simulation);

        return {
            isLoadingLanguage,
            simulation,
            hasSimulationInstanceLoadedSuccessfully,
            simulationLoadingError,
        };
    },
);

export function useSimulation(): Ref<Simulation | undefined> {
    return useSimulationInstanceStore()?.simulation ?? ref(undefined);
}

/**
 * Returns the simulation instance if it is loaded, otherwise throws an error.
 * Only use this in areas where the simulation instance is loaded and injected.
 */
export function useNonNullSimulation(): Ref<Simulation> {
    const simulationInstanceStore = useSimulationInstanceStore();
    if (isNullable(simulationInstanceStore)) {
        throw new FrontendError('errors.frontend.simulationNotProvided');
    }

    return computed(() => {
        if (isNullable(simulationInstanceStore.simulation.value)) {
            throw new FrontendError('errors.frontend.simulationNotLoaded');
        }
        return simulationInstanceStore.simulation.value!;
    });
}

export function getScenarioAssetUrl(scenario: FrontendScenarioDefinition | undefined, fileName: string): string {
    const scenarioPrefix = isNonNullable(scenario?.assetsDirectory) ? `${scenario.assetsDirectory}/` : '';
    return supabaseClient.storage.from('scenario_assets').getPublicUrl(`${scenarioPrefix}${fileName}`).data.publicUrl;
}
